/* Estilizando o Header */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #F3F3F3;
  color: #333;
  line-height: 1.6;
}

.ant-table th, .ant-table td {
  white-space: nowrap; /* Evita quebras de linha dentro das células */
  text-overflow: ellipsis; /* Adiciona reticências se o texto for muito longo */
  overflow: hidden; /* Esconde o conteúdo que ultrapassa o tamanho da célula */
}

.ant-table-cell {
  width: auto !important; /* Garante que a largura das colunas seja baseada no conteúdo */
}


.ant-message {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 1000; /* Certifique-se de que está acima de outros elementos da interface */
}

.header {
  background-color: #1E22AA; /* Azul UNE */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

.header-title {
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.login-title {
  font-size: 24px;
  font-weight: 700;
  color: #1E22AA;
  text-align: center;
  margin-bottom: 20px;
}

.login-form-item {
  margin-bottom: 16px;
}

.login-button {
  width: 100%;
  background-color: #00BFB2;
  border-color: #00BFB2;
  font-weight: 700;
}

.login-button:hover {
  background-color: #019D8F;
  border-color: #019D8F;
}

.login-remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-remember .ant-checkbox-wrapper {
  color: #575756;
}

.login-remember a {
  color: #1E22AA;
}

.login-remember a:hover {
  text-decoration: underline;
}

.login-footer {
  margin-top: 20px;
  text-align: center;
  color: #979797;
}

.login-footer a {
  color: #00BFB2;
}

.login-footer a:hover {
  color: #019D8F;
  text-decoration: underline;
}

.logout-button {
  background-color: #00BFB2; /* Verde UNE */
  border-color: #00BFB2;
}

.logout-button:hover {
  background-color: #019D8F;
  border-color: #019D8F;
}

/* Estilizando o Sidebar */
.sidebar-menu {
  background-color: #333333; /* Cinza Escuro UNE */
  border-right: 1px solid #1E22AA;
}

.sidebar-menu .ant-menu-item-selected {
  background-color: #00BFB2 !important; /* Verde UNE */
}

.sidebar-menu .ant-menu-item {
  color: white;
  font-weight: 600;
}

.sidebar-menu .ant-menu-item:hover {
  background-color: #575756; /* Cinza Médio UNE */
}

/* Estilizando o Layout */
.layout-content {
  margin: 16px;
  background-color: #F3F3F3; /* Cinza Claro UNE */
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.container {
  padding: 16px;
}

/* Default button styles */
.button {
  margin-right: 8px;
}

/* Ant Design overrides or custom styles */
.ant-table {
  margin-top: 16px;
}

.ant-select, .ant-input {
  width: 100%;
}

/* Add more global styles as needed */
.main-table-container {
  padding: 16px;
}

.filters-row {
  margin-bottom: 16px;
}

.filter-select, .filter-input, .ant-tree-select {
  width: 100%;
}

.filter-button {
  margin-right: 8px;
}

